<template>
  <b-card>

    <!-- search input -->
    <b-row class="mb-1">
      <b-col lg="4">
        <b-form-input
          v-model.lazy="searchTerm"
          placeholder="Search..."
          type="text"
          class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          @keyup="onSearch"
        />
      </b-col>
      <b-col
        lg="8"
        class="d-flex justify-content-end align-items-center"
      >
        <template v-if="$permissionAbility(DEPARTMENT_CREATE, permissions)">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="flex-shrink-0"
            variant="primary"
            @click="showModal"
          >
            Create Department
          </b-button>
        </template>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      mode="remote"
      :total-rows="totalRecords"
      :is-loading.sync="isLoading"
      :rows="rows"
      :sort-options="{
        enabled: false,
        multipleColumns: true,
        initialSortBy: [
          { field: 'id', type: 'desc' },
          { field: 'name', type: 'desc' },
          { field: 'created_at', type: 'desc' },
        ],
      }"
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- head -->
        <template v-if="$permissionAbility(DEPARTMENT_SHOW, permissions)">
          <span v-if="props.column.field === 'format_name'">
            <b-link @click="onShowPage(props.row.id)">{{
              props?.row?.name
            }}</b-link>
          </span>
        </template>

        <template v-else>
          <span v-if="props.column.field === 'format_name'">
            <span class="cursor-pointer" @click="onShowPage(props.row.id)">
             {{ props?.row?.name }}
            </span>
          </span>
        </template>

        <span v-if="props.column.field === 'format_users_count'">
          <b-badge
            class="ml-1"
            variant="light-primary"
          >
            {{ props?.row?.users_count }}
          </b-badge>
        </span>

        <span v-if="props.column.field === 'head_user'">
          <template v-if="props?.row?.head_id != null">

            <UserCard :user="props?.row?.head?.data" />
          </template>
        </span>

        <span v-else-if="props.column.field === 'action'">

          <template
            v-if="$permissionAbility(DEPARTMENT_SHOW, permissions)"
          >
            <span @click="onShowPage(props.row.id)">
              <feather-icon
                v-b-tooltip.hover
                icon="EyeIcon"
                class="mr-50 custom-icon cursor-pointer"
                title="View"
                size="16"
              />
            </span>
          </template>

          <template v-if="$permissionAbility(DEPARTMENT_EDIT, permissions)">
            <span @click="onShow(props.row)">

              <feather-icon
                v-b-tooltip.hover
                icon="Edit2Icon"
                class="mr-50 custom-icon cursor-pointer"
                title="Edit"
                size="16"
              />

            </span>
          </template>

          <template v-if="$permissionAbility(DEPARTMENT_DELETE, permissions)">
            <span @click="onDelete(props.row)">
              <feather-icon
                v-b-tooltip.hover
                icon="TrashIcon"
                class="mr-50 custom-icon cursor-pointer"
                title="Delete"
                size="16"
              />
            </span>
          </template>

        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['10', '25', '50', '100', '500']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <b-modal
      id="modal-department-form"
      centered
      :title="modelType === 'editModel' ? 'Edit' : 'Create'"
      hide-footer
      no-close-on-backdrop
      @hidden="hiddenModal"
    >
      <validation-observer ref="departmentValidation">
        <b-form @submit.prevent="validationForm">
          <!-- name -->
          <b-form-group
            label="Name"
            label-for="name"
            class="required-label"
          >
            <validation-provider
              #default="{ errors }"
              name="name"
              vid="name"
              rules="required|max:255"
            >
              <b-form-input
                id="name"
                v-model="name"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="Department"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- select head-->
          <b-form-group
            label="Head"
            label-for="head-id"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="head_id"
              vid="head_id"
            >
              <v-select
                id="head-id"
                v-model="selectHeadId"
                placeholder="Choose a Head"
                :options="headIdOption"
                :reduce="(country) => country.id"
                label="name"
              >
                <template #option="data">
                  <div class="d-flex align-items-center">
                    <b-avatar
                      :src="data?.avatar === '' ? '/avatar.svg' : data?.avatar"
                      class="mr-1"
                    />
                    <div>
                      <div class="font-weight-bolder">
                        {{ data?.name }}
                        <b-badge
                          pill
                          variant="warning"
                        >{{
                            data?.employee_number
                        }}</b-badge>
                      </div>
                      <div class="font-small-2">
                        {{ data?.email }}
                      </div>
                    </div>
                  </div>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- select Division -->
          <b-form-group
            label="Division"
            label-for="division-id"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="division_id"
              vid="division_id"
            >
              <v-select
                id="division-id"
                v-model="selectDivisionId"
                placeholder="Choose a Division"
                :options="divisionIdOption"
                :reduce="(country) => country.id"
                label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isDepartmentFormSubmitLoading">
            <b-button
              class="float-right"
              variant="primary"
              disabled
            >
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              class="float-right"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BLink, VBTooltip, BRow, BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max } from '@validations'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import {
  DEPARTMENT_CREATE,
  DEPARTMENT_SHOW,
  DEPARTMENT_EDIT,
  DEPARTMENT_DELETE,
} from '@/helpers/permissionsConstant'
import UserCard from '@/layouts/components/UserCard.vue'

export default {
  name: 'DepartmentView',
  components: {
    BCol,
    BRow,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BLink,
    UserCard,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      DEPARTMENT_CREATE,
      DEPARTMENT_SHOW,
      DEPARTMENT_EDIT,
      DEPARTMENT_DELETE,
      modelType: '',
      departmentId: '',
      name: '',
      selectHeadId: '',
      headIdOption: [],
      selectDivisionId: '',
      divisionIdOption: [],
      pageLength: 10,
      columns: [
        {
          label: 'Name',
          field: 'format_name',
        },
        {
          label: 'Department Head',
          field: 'head_user',
          sortable: false,
        },
        {
          label: 'Division',
          field: 'division.data.name',
          sortable: false,
        },
        {
          label: 'No. of Employee',
          field: 'format_users_count',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      delayTimer: null,
      isLoading: false,
      isDepartmentFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: 'id', type: 'desc' },
          { field: 'name', type: 'desc' },
          { field: 'created_at', type: 'desc' },
        ],
        page: 1,
        perPage: 10,
      },
    }
  },

  computed: {
    ...mapGetters({
      permissions: 'userModule/getPermissions',
    }),
  },

  async created() {
    try {
      const [users, divisions] = await Promise.all([
        this.getUsers(),
        this.getDivisions(),
      ])

      // users
      this.headIdOption = (users?.data?.data || []).map(item => ({
        name: item?.name,
        email: item?.email,
        mobile: item?.mobile,
        employee_number: item?.employee_number,
        id: item?.id,
        departmentId: item?.department_id,
      }))

      // divisions
      this.divisionIdOption = (divisions?.data?.data || []).map(item => ({
        name: item.name,
        id: item.id,
      }))
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'BellIcon',
          variant: 'danger',
          text: error?.response?.data?.message,
        },
      })
    }
  },

  methods: {
    showModal() {
      this.$bvModal.show('modal-department-form')
    },
    hiddenModal() {
      this.$bvModal.hide('modal-department-form')
      this.resetModal()
    },
    resetModal() {
      this.modelType = ''
      this.name = ''
      this.selectHeadId = ''
      this.departmentId = ''
      this.selectDivisionId = ''
    },
    onShowPage(id) {
      this.$router.push({
        name: 'admin-department-details',
        params: { id },
      })
    },
    async onShow(value) {
      this.modelType = 'editModel'
      this.name = value.name
      this.departmentId = value?.id ? +value?.id : ''
      this.selectHeadId = value?.head_id ? +value?.head_id : ''
      this.selectDivisionId = value?.division_id ? +value?.division_id : ''

      this.showModal()
    },
    async onDelete(row) {
      const { id, name } = row
      this.$swal({
        title: 'Warning!',
        text: `Are You Sure You Want To Delete ${name}?`,
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/departments/${id}`)

            this.loadItems()

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Deleted Successfully',
              },
            })
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                variant: 'danger',
                text: error?.response?.data?.message,
              },
            })
          }
        }
      })
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer)
        this.delayTimer = null
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems()
      }, 1000)
    },

    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    async getDepartments(params) {
      return await this.$api.get('api/departments?include=head,division', {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      })
    },
    async getUsers() {
      return await this.$api.get('api/users/all?include=designation')
    },
    async getDivisions() {
      return await this.$api.get('api/divisions/all')
    },

    async loadItems() {
      try {
        const [departments] = await Promise.all([
          this.getDepartments({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
        ])

        const data = departments?.data?.data

        const meta = departments?.data?.meta

        this.totalRecords = meta?.pagination?.total
        this.rows = data
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },
    async validationForm() {
      this.$refs.departmentValidation.validate().then(async success => {
        if (success) {
          try {
            if (this.modelType == 'editModel') {
              this.isDepartmentFormSubmitLoading = true
              await this.$api.put(`/api/departments/${this.departmentId}`, {
                name: this.name,
                head_id: this.selectHeadId,
                division_id: this.selectDivisionId,
              })
              this.isDepartmentFormSubmitLoading = false
              this.loadItems()
              this.hiddenModal()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: 'Successfully Updated',
                },
              })
            } else {
              this.isDepartmentFormSubmitLoading = true
              await this.$api.post(
                '/api/departments',

                {
                  name: this.name,
                  head_id: this.selectHeadId,
                  division_id: this.selectDivisionId,
                },
              )
              this.isDepartmentFormSubmitLoading = false
              this.hiddenModal()

              this.loadItems()

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: 'Successfully Created',
                },
              })
            }
          } catch (error) {
            this.isDepartmentFormSubmitLoading = false

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: error?.response?.data?.message,
                },
              })
            }

            if (error?.response?.data?.errors) {
              this.$refs.departmentValidation.setErrors(
                error?.response?.data?.errors,
              )
            }
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.required-label label::after {
  content: " *";
  color: red;
}

</style>
